/*
 * import to your layout/index.js
 */

import React, { useEffect, useRef, useCallback } from 'react'
import { motionTransition, scrollTransition, withRouter } from '../util'
import { useMotionScroll } from '../hooks/use-motion-scroll'

// Finds y value of given object
// https://stackoverflow.com/questions/4801655/how-to-go-to-a-specific-element-on-page
function findPos(obj) {
  let curtop = 0

  if (obj.offsetParent) {
    do {
      curtop += obj.offsetTop
    } while ((obj = obj.offsetParent))
    return curtop
  }
}


const HashChangeHandler = withRouter( ({ router: { location }, onChange }) => {
  const scrollParent = useRef(null)
  const pathname = useRef(null)
  const {scrollTo, cancelScroll} = useMotionScroll(scrollParent, scrollTransition)

  const handleHashChange = useCallback(
    () => {
      // only handle within the same /pathname
      if (location.pathname !== window.location.pathname) {
        return
      }

      if (window.location.hash.startsWith(`#`)) {
        try {
          const selector = `#${window.location.hash.split(`#`).pop()}`
          /* destination element to scroll to */
          const destElem = document.querySelector(selector)
          
          const scrollTop = (destElem && findPos(destElem)) || 0
          scrollTo(scrollTop)
        } catch(e) {
          scrollTo(0)
        }
      } else {
        scrollTo(0)
      }

      onChange && onChange()
    },
    [location.pathname, onChange, scrollTo]
  )

  const handleClick = useCallback(e => {
    if (e.target.tagName.toLowerCase() !== `a`) {
      return
    }

    const isExternal = e.target.hostname !== location.hostname

    if (! isExternal && e.target.pathname === location.pathname) {
      e.preventDefault()

      if (e.target.hash !== location.hash) {
        window.history.pushState(null, null, `${location.pathname}${e.target.hash}`)
        window.dispatchEvent( new Event(`hashchange`) )
      }
    }
    
  }, [location.hash, location.hostname, location.pathname])

  useEffect(() => {
    document.addEventListener(`click`, handleClick)
    
    return () => document.removeEventListener(`click`, handleClick)
  }, [handleClick, location])

  useEffect(() => {
    scrollParent.current = window
    window.addEventListener(`hashchange`, handleHashChange)
    
    return () => {
      window.removeEventListener(`hashchange`, handleHashChange)
    } 
  }, [handleClick, handleHashChange])

  return null
})


export default HashChangeHandler
