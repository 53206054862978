import React, {createContext, useContext, useReducer } from 'react'
// import { useLayoutContext } from './layout'

function getDefaultReducer(defaults) {
  return (prevState, payload) => {
    const newState = { ...prevState, ...defaults, ...payload }
  
    if ( JSON.stringify(prevState) === JSON.stringify(newState) ) {
      return prevState
    }
    
    return newState
  }
}

export default function createContextStore({
  initial = {},
  dispatchDefaults = {},
  reducer = getDefaultReducer(dispatchDefaults),
} = {}) {

  const StateContext = createContext();
  
  const StateProvider = ({reducer, initialState, children}) => (
    <StateContext.Provider value={useReducer(reducer, initialState)}>
      {children}
    </StateContext.Provider>
  )
  
  const useStore = () => useContext(StateContext)

  const initialState = { ...dispatchDefaults, ...initial }

  const StoreProvider = ({ children }) => (
    <StateProvider
      reducer={reducer}
      initialState={initialState}
    >
      {children}
    </StateProvider>
  )

  return [StoreProvider, useStore, StateContext]
} 
