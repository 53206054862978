import React, { useCallback } from 'react'
import { motion } from 'framer-motion'
import styled from 'styled-components'
import { motionTransition, withRouter } from '../util'
import { useStaticQuery, graphql } from 'gatsby'
import tw from 'tailwind.macro'
import { theme } from '../twconfig'

const menuItems = [
  {
    title: `Philosophy`,
    href: `#philosophy`,
  },
  {
    title: `Clients`,
    href: `#clients`,
  },
  {
    title: `Case Studies`,
    href: `#projects`,
  },
  {
    title: `Our Stack`,
    href: `#our-stack`,
  },
  {
    title: `Dev Diaries`,
    href: `#dev-diaries`,
  },
  {
    title: `Contact`,
    href: `#contact`,
  },
  {
    title: `Brand & Web Design`,
    href: `https://ralev.com/`,
  },
]

// const listTransition = {
//   ...motionTransition,
// }

const listItemTransition = {
  ...motionTransition,
  mass: .25,
  damping: 30,
  stiffness: 50,
}

const listVariants = {
  opened: {
    transition: {
      when: `beforeChildren`,
      staggerChildren: .1,
    }
  },
  
  closed: {
    transition: {
      when: `afterChildren`,
      staggerChildren: .033,
    },
  },
}

const visibleVariant = {
  opacity: 1,
  y: 0,
}

const hiddenVariant = {
  opacity: 0,
  y: -16,
  transitionEnd: { y: 16 }, 
}

const initVariant = {
  opacity: 0,
  y: 16,
}

const listItemVariants = {
  opened: visibleVariant,
  closed: hiddenVariant,
  init: initVariant,
}

const MainNavBase = withRouter(({ router: { location, navigate }, ...props}) => {
  const { pageHome: { route } } = useStaticQuery(graphql`
    {
      pageHome { route }
    }
  `)

  const preventClickBubble = useCallback((e) => {
    e.stopPropagation()
  }, [])

  const handleClick = useCallback(e => {
    const { currentTarget } = e

    console.log(`currentTarget.hostname: `, currentTarget.hostname)
    console.log(`location.hostname: `, location.hostname)

    if ( currentTarget.hostname !== location.hostname ) {
      console.log(`bailing from click handler`)
      return
    }

    e.preventDefault()
    
    if ( currentTarget.pathname === location.pathname ) {
      return
    }

    navigate(currentTarget.href)
  }, [location.hostname, location.pathname, navigate])

  return (
    <motion.nav {...props} onClick={preventClickBubble}>
      {menuItems ? (
        <motion.ul variants={listVariants}>
          {menuItems.map(({ title, href }) => {
            const linkProps = {}
            const isExternal = /^https?:/.test(href)

            const link = isExternal ? href : `${route}${href}`
            if ( isExternal ) {
              Object.assign(linkProps, {
                target: `_blank`,
                rel: `noreferrer noopener`,
              })
            }

            return (
              <ListItem key={href} variants={listItemVariants} transition={listItemTransition} initial="init">
                <a onClick={handleClick} href={link} {...linkProps}>
                  <span>{title}</span>
                </a>
              </ListItem>
            )
          })}
        </motion.ul>
      ) : null}
    </motion.nav>
  )
})

const ListItem = styled(motion.li)`
  a {
    display: inline-block;
    position: relative;
    ${tw`px-1`}

    &::before {
      content: '';
      ${tw`absolute inset-0`}
      background-color: ${ theme.colors[`brand-50`] };
      transform: scaleY(0);
      transition: transform .3s ease-in-out;
      transform-origin: 0% 0%;
      /* z-index: 0; */
    }
    
    &:focus, &:hover {
      &::before {
        transform: scaleY(1);
        transform-origin: 0% 100%;
      }
    }
    
    span {
      position: relative;
      pointer-events: none;
      /* z-index: 1; */
    }
  }
`

const MainNav = styled(MainNavBase)``

export default MainNav
