import React, { useEffect, useState, useCallback, useMemo, useRef } from 'react'
import { motion, useViewportScroll, useAnimation } from "framer-motion"
import { theme } from '../twconfig'

const firstLetterVariants = {
  top: { fill: theme.colors.white },
  scrolled: { fill: theme.colors.brand },
}

const closingBraketVariants = {
  top: { translateX: 0, },
  scrolled: { translateX: -38, }
}

const clipPathVariants = {
  top: { scaleX: 1, originX: 0 },
  scrolled: { scaleX: 0, originX: 0 },
}

const springTransition = {
  damping: 30,
  mass: .7,
  stiffness: 10,
  restSpeed: .5,
  restDelta: .5,
}

const Logo = () => {
  const {scrollY} = useViewportScroll()
  const animationControl = useAnimation()
  const currentVariant = useRef()

  const handleScrollProgress = useCallback(progress => {
    if(progress >= 100 && currentVariant.current !== `scrolled`) {
      animationControl.start(`scrolled`)
      currentVariant.current = `scrolled`
    } else if ( progress < 100 && currentVariant.current !== `top` ) {
      animationControl.start(`top`)
      currentVariant.current = `top`
    }
  }, [animationControl])

  useEffect(() => {
    return scrollY.onChange(handleScrollProgress)
  },[handleScrollProgress, scrollY])

  return (
    <div className="">
      <motion.svg
        className="fill-current inline-block h-6 align-middle"
        viewBox="0 0 89 22"
        xmlns="http://www.w3.org/2000/svg"
      >
        <defs>
          <clipPath
            id="cut-off-text"
          >
            <motion.rect
              x="28"
              y="0"
              width="42"
              height="22"
              variants={clipPathVariants}
              initial="top"
              animate={animationControl}
              transition={springTransition}
            />
          </clipPath>
        </defs>
        <g>
          <polygon points="9.5,8.4 3.3,10.7 9.5,13 9.5,14.9 1.2,11.7 1.2,9.7 9.5,6.5 " />
          <motion.path
            variants={firstLetterVariants}
            initial="top"
            animate={animationControl}
            d="M25.1,12.9c0.9-0.3,1.5-0.8,2-1.5c0.5-0.7,0.7-1.5,0.7-2.4c0-0.9-0.2-1.7-0.6-2.4c-0.4-0.7-1.1-1.2-1.9-1.5  c-0.8-0.4-1.8-0.5-2.8-0.5h-5.2V17h2.3v-3.7h2.8c0.2,0,0.4,0,0.5,0l2.6,3.7H28L25.1,12.9z M22.4,11.4h-2.7v-5h2.7  c1,0,1.8,0.2,2.3,0.6c0.5,0.4,0.8,1,0.8,1.9s-0.3,1.4-0.8,1.9C24.2,11.2,23.4,11.4,22.4,11.4z"
          />

          <g clipPath="url(#cut-off-text)" fill={theme.colors.white}>
            <path d="M 37.1 8.3 c -0.7 -0.7 -1.8 -1 -3.2 -1 c -0.8 0 -1.5 0.1 -2.3 0.3 c -0.7 0.2 -1.3 0.5 -1.8 0.9 l 0.9 1.6 c 0.4 -0.3 0.8 -0.5 1.3 -0.7 c 0.5 -0.2 1.1 -0.3 1.6 -0.3 c 0.8 0 1.4 0.2 1.8 0.5 c 0.4 0.4 0.6 0.9 0.6 1.5 v 0.1 h -2.5 c -1.4 0 -2.4 0.3 -3 0.8 c -0.6 0.5 -1 1.2 -1 2.1 c 0 0.6 0.1 1 0.4 1.5 c 0.3 0.4 0.7 0.8 1.3 1 c 0.5 0.2 1.2 0.4 1.9 0.4 c 0.7 0 1.3 -0.1 1.8 -0.3 c 0.5 -0.2 0.9 -0.6 1.2 -1 V 17 h 2.1 v -5.6 C 38.2 10 37.8 9 37.1 8.3 Z M 35.9 14 c -0.2 0.5 -0.5 0.9 -0.9 1.1 c -0.4 0.3 -0.9 0.4 -1.5 0.4 c -0.6 0 -1 -0.1 -1.3 -0.4 c -0.3 -0.2 -0.5 -0.6 -0.5 -1 c 0 -0.9 0.6 -1.3 1.9 -1.3 h 2.3 V 14 Z" />
            <rect x="41.1" y="3.6" width="2.2" height="13.4" />
            <path d="M 53 7.9 c -0.7 -0.4 -1.6 -0.6 -2.5 -0.6 c -0.9 0 -1.8 0.2 -2.6 0.6 c -0.8 0.4 -1.3 1 -1.8 1.8 c -0.4 0.7 -0.6 1.6 -0.6 2.5 c 0 0.9 0.2 1.8 0.6 2.6 c 0.4 0.7 1.1 1.3 1.9 1.7 c 0.8 0.4 1.7 0.6 2.8 0.6 c 0.8 0 1.6 -0.1 2.2 -0.4 c 0.7 -0.3 1.2 -0.7 1.6 -1.2 l -1.2 -1.4 c -0.7 0.7 -1.6 1 -2.6 1 c -0.8 0 -1.5 -0.2 -2.1 -0.6 c -0.6 -0.4 -0.9 -1 -1 -1.7 h 7.5 c 0 -0.3 0 -0.5 0 -0.7 c 0 -1 -0.2 -1.8 -0.6 -2.6 C 54.3 8.9 53.7 8.3 53 7.9 Z M 47.7 11.4 c 0.1 -0.7 0.4 -1.3 0.9 -1.7 c 0.5 -0.4 1.1 -0.6 1.8 -0.6 c 0.7 0 1.3 0.2 1.8 0.6 s 0.8 1 0.9 1.7 H 47.7 Z" />
            <polygon points="61.1,14.5 58.1,7.4 55.7,7.4 59.9,17 62.2,17 66.3,7.4 64.1,7.4 " />
          </g>

          <motion.g
            transition={springTransition}
            variants={closingBraketVariants}
            initial="top"
            animate={animationControl}
          >
            <polygon points="79.1,6.5 79.1,8.4 85.3,10.7 79.1,13 79.1,14.9 87.4,11.7 87.4,9.7 " />
            <polygon points="70.7,18.8 72.7,18.8 78.6,1.8 76.6,1.8 " />
          </motion.g>
        </g>
      </motion.svg>
    </div>
  )
}

export default Logo
