import React, { useEffect, useRef, useCallback, forwardRef } from 'react'
import { motion, useAnimation } from 'framer-motion'
import styled from 'styled-components'
import { motionTransition, delay } from '../util'
import PropTypes from 'prop-types'

const transition = {
  ...motionTransition,
  mass: .15,
}

const BarsSvg = styled(motion.svg)`
  transition: all .3s ease-in-out;
`

const svgVariants = {
  visible: {
    transition: {
      staggerChildren: .15,
    },
  },
}

const barVariants = {
  init: {
    rotate: 0,
    opacity: 0,
    y: `15%`,
  },
  
  visible: {
    rotate: 0,
    opacity: 1,
    y: 0,
  },
}

const topBarVariants = {
  ...barVariants,
  
  menuOpened: {
    d: `M 6.3379419,3.8630681 26.136932,23.662058 23.662058,26.136932 3.8630682,6.3379418 6.3379419,3.8630681`,
  },

  menuClosed: {
    d: `M 1,1 29,1 29,4.5 1,4.5 1,1`,
  },
}

const middleBarVariants = {
  ...barVariants,

  menuOpened: {
    scale: 0,
  },
  
  menuClosed: {
    scale: 1,
  },
}

const bottomBarVariants = {
  ...barVariants,
  menuOpened: {
    d: `M 3.8630682,23.662058 23.662058,3.863068 26.136932,6.337942 6.3379419,26.136932 3.8630682,23.662058`,
  },

  menuClosed: {
    d: `M 1,25.5 29,25.5 29,29 1,29 1,25.5`,
  },
}

const NavToggler = forwardRef( ({ className = ``, size = 24, onClick, buttonAnimation = null, ...props }, ref) => {
  const animation = useAnimation()

  useEffect(() => {
    if ( buttonAnimation ) {
      buttonAnimation.current = animation
    }

  }, [animation, buttonAnimation])

  const clickHandler = useCallback(e => {
    e.preventDefault()
    onClick()
  }, [onClick])

  return (
    <button aria-label="Navigation" ref={ref} onClick={clickHandler} className={`focus:outline-none cursor-pointer ${className}`}>
      <BarsSvg
        width={size}
        height={size}
        viewBox="0 0 30 30"
        variants={svgVariants}
        animate={animation}
        xmlns="http://www.w3.org/2000/svg"
      >
        <g fill="currentColor">
          <motion.path
            initial="init"
            variants={topBarVariants}
            d="M 1,1 29,1 29,4.5 1,4.5 1,1"
            transition={transition}
            />

          <motion.path
            initial="init"
            variants={middleBarVariants}
            d="m 1,13.25 28,0 0,3.5 -28,0 z"
            transition={transition}
            />

          <motion.path
            initial="init"
            variants={bottomBarVariants}
            d="M 1,25.5 29,25.5 29,29 1,29 1,25.5"
            transition={transition}
            style={{ originY: `100%`, }}
          />
        </g>
      </BarsSvg>
    </button>
  )
})

NavToggler.defaultProps = {
  onClick: () => {}
}

NavToggler.defaultProps = {
  onClick: PropTypes.func,
}

export default NavToggler
