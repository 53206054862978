import { useRef, useEffect, useCallback, useMemo } from 'react'
import { spring } from 'popmotion'
import raf from 'raf'

export function useMotionScroll(userTarget, springConfig = {}) {
  const action = useRef()
  const target = useRef()
  
  const syncScroll = useCallback(value => {
    raf(() => {
      if ( target.current ) {
        if (target.current.scrollTo) {
          target.current.scrollTo(0, value)
        } else {
          target.current.scrollTop = value
        }
      }
    })
    
  }, [])
  
  const scrollTo = useCallback(value => {
    if ( ! target.current ) {
      return
    }

    const from = target.current.scrollY || target.current.scrollTop
    action.current = spring({ from, to: value, ...springConfig }).start({
      update: syncScroll,
    })
    
  }, [springConfig, syncScroll])

  const cancelScroll = useCallback(() => {
    if ( action.current && action.current.stop ) {
      action.current.stop()
    }
  }, [])

  useEffect(() => {
    target.current = (userTarget && userTarget.current) ? userTarget.current : window
  }, [userTarget])

  useEffect(() => {
    window.addEventListener(`wheel`, cancelScroll)
    document.body.addEventListener(`click`, cancelScroll)
    
    return () => {
      window.removeEventListener(`wheel`, cancelScroll)
      document.body.removeEventListener(`click`, cancelScroll)
    }
  }, [cancelScroll])
  
  const hookResult = useMemo(() => ( {scrollTo, cancelScroll}), [cancelScroll, scrollTo])
  return hookResult
}
