/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import { cancelWindowMotionScroll } from 'components/hash-change-handler'
import 'promise-polyfill/dist/polyfill.min.js'

function triggerHashChange() {
  window.dispatchEvent( new Event(`hashchange`) )
}

export const onInitialClientRender = () => {
  if (window.location.hash && window.location.hash.length > 1) {
    triggerHashChange()
  }
}

export const onClientEntry = async () => {
  const polyfills = []
  
  if (! window.IntersectionObserver) {
    polyfills.push(
      import(`intersection-observer`)
    )
  }

  if (typeof(window.Event) !== `function`) {
    polyfills.push(
      import(`events-polyfill/src/constructors/Event`)
    )
  }

  await Promise.all(polyfills)
}

export { default as wrapRootElement } from 'components/root-wrapper' 
