import { useEffect, useRef } from 'react'
import createContextStore from './context-store-factory'

const initialState = {
  menuOpen: false,
}

const stateDefaults = {
}

export const [HeaderStateProvider, useHeaderStore, HeaderContext] = createContextStore({
  initial: initialState,
  dispatchDefaults: stateDefaults,
})
