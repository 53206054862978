/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useLayoutEffect, useEffect, useState, useCallback } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyDataProvider } from 'context/gatsby-data'
import Header from 'components/header'
import 'styles/main.css'
import styled from 'styled-components'
import tw from 'tailwind.macro'
// eslint-disable-next-line import/no-webpack-loader-syntax
import FooterLogoBase from '!!@svgr/webpack!@/src/images/logo.svg'
import HashChangeHandler from "../components/hash-change-handler"
import ParallaxCache from "../components/parallax-cache"
import { AnimatePresence } from "framer-motion"

const FooterLogo = styled(FooterLogoBase)`
  path {
    fill: inherit!important;
  }
`

const Main = styled.main`
  ${tw`mt-12 lg:mt-20`}
`

const WithDynamicBackground = styled.div`
  &::before {
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    ${({ bgColor }) => `background-color: ${bgColor};`}
  }
`


const Layout = ({ data, location, children }) => {
  const [mounted, setMounted] = useState(false)

  const metaData = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  useEffect(() => {
    setMounted(true)
  }, [])

  return (
    <GatsbyDataProvider value={data}>
      {/* <WithDynamicBackground id="app" className="flex flex-col text-white" bgColor="rgba(0,0,0,.9)"> */}
        <Header
          className="header fixed z-150 top-0 w-full flex items-center text-white px-4 pr-12 py-2 h-12 lg:pl-6 lg:pr-20 lg:py-6 lg:h-20"
          siteTitle={metaData.site.siteMetadata.title}
        />
        
          <AnimatePresence initial={false}>
            <React.Fragment key={location.pathname}>
              {children}
            </React.Fragment>
          </AnimatePresence>

        <footer className="text-xs flex flex-row flex-no-wrap bg-gray-600 py-4 px-5 items-center">
          <div className="md:whitespace-no-wrap">
            <FooterLogo className="inline-block align-middle fill-current text-gray-400 w-16" />
            <br className="md:hidden" />
            {" "}
            <span className="hidden md:inline">&ndash;</span> a project of
            {" "}
            <a target="_blank" rel="noreferrer noopener" href="https://ralev.com">Ralev.com</a>
          </div>

          <div className="credits ml-auto text-right">
            2004 &ndash; {new Date().getFullYear()} &copy; <br className="md:hidden" />All rights reserved
          </div>
        </footer>

        <HashChangeHandler />
        {mounted && <ParallaxCache />}

      {/* </WithDynamicBackground> */}
    </GatsbyDataProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
