import { useLayoutEffect, useCallback } from 'react'
import { withController } from 'react-scroll-parallax'
import { withRouter } from '../util'
import { debounce } from 'lodash'
import raf from 'raf'

const ParallaxCache = withController(
  withRouter(({ parallaxController, router: { location } }) => {
    const update = useCallback(
      debounce(() => {
        parallaxController.update()
      }, 150),
      [parallaxController]
    )

    useLayoutEffect(() => {
      const handler = () => {
        raf(update)
      }

      window.addEventListener("load", handler)
      window.addEventListener(`resize`, handler)
      window.parallaxUpdate = update

      return () => {
        window.removeEventListener("load", handler)
        window.removeEventListener(`resize`, handler)
      }
    }, [update])

    useLayoutEffect(() => {
      update()
      // const timeout = setTimeout(update, 100)
      // return () => clearTimeout(timeout)
    }, [location, update])

    return null
  })
)

export default ParallaxCache
