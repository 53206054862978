const Color = require('color')
const { colors } = require('tailwindcss/defaultTheme.js')

module.exports = {
  theme: {
    fontFamily: {
      serif: ['Montserrat', 'serif'],
    },
    rotate: {
      '90': '90deg',
      '180': '180deg',
      '270': '270deg',
    },
    extend: {
      screens:{
        xxl: '1400px',
        xxxl: '1600px',
      },
      colors: {
        'inherit': 'inherit',
        'brand': '#ec008e',
        'brand-50': Color(`#ec008e`).fade(.5).string(),
        gray: {
          ...colors.gray,
          '200': '#d6d6d6',
          '400': '#8b8b8b',
          '500': '#383838',
          '600': '#222',
        },
      },
      margin: {
        '24': '6rem',
        '28': '7rem',
        '32': '8rem',
      },
      fontSize: {
        '5xl': '2.5rem',
        '6xl': '3rem',
        '7xl': '3.5rem',
        '8xl': '4rem',
      },
      lineHeight: {
        extraloose: '2.5',
      },
      inset: {
        '1/2': '50%',
        'full': '100%',
        '-1/3' : '-30%'
      },
      zIndex: {
        '100': 100,
        '150': 150,
        '200': 200,
      },
      maxWidth: {
        'none': `none`,
        'screen-1/2': '50vh',
        'screen-1/3': '33.33vh',
      },
      minHeight: {
        'screen-1/2': '50vh',
        'screen-1/3': '33.33vh',
      },
      stroke: theme => ({
        transparent: `transparent`,
        brand: theme('colors.brand'),
        black: theme('colors.black'),
        'gray-600': theme('colors.gray.600'),
      }),
      fill: theme => ({
        transparent: `transparent`,
        brand: theme('colors.brand'),
        black: theme('colors.black'),
        'gray-600': theme('colors.gray.600'),
      })
    },
    container: {
      center: true,
      padding: '1.5rem',
    },
  },
  variants: {
    margin: ['responsive', 'last']
  },
  plugins: [
    require('tailwindcss-transforms')(),
  ],
}
