import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, { useRef, useEffect, useCallback, useMemo } from "react"
import Logo from 'components/Logo' 
import NavTogglerBase from "./nav-toggler"
import usePortal from 'react-useportal'
import { useHeaderStore } from "../context/header"
import { motion, AnimatePresence } from 'framer-motion'
import { delay, motionTransition, withRouter } from "../util"
import styled from 'styled-components'
import MainNav from "./main-nav"
import { createGlobalStyle } from 'styled-components'
import tw from 'tailwind.macro'
import { useMediaQueryContext } from 'context/media'

const NavToggler = styled(NavTogglerBase)`
.header & {
  top: 50%;
  transform: translateY(-50%);
}
`

const menuVariants = {
  closed: {
    opacity: 0,
    scale: 1.25,
    transition: {
      // ...motionTransition,
      // ...transition,
      damping: 5,
      mass: .5,
      when: `afterChildren`,
    },
    transitionEnd: {
      display: `none`,
    }
  },
  
  opened: {
    display: `flex`,
    opacity: 1,
    scale: 1,
    transition: {
      // ...motionTransition,
      // ...transition,
      when: `beforeChildren`,
      damping: 5,
      mass: .5,
    },
  },
}

const MainNavStyles = createGlobalStyle`
  .menu-overlay {
    ${MainNav} {
      text-align: center;
      text-transform: uppercase;

      a {
        ${tw`text-xl font-bold leading-extraloose`}
      }
    }
  }
`

const desktopMainNavVariants = {

}

const HeaderBase = withRouter(({ router, siteTitle, className, ...props }) => {
  const togglerAnimation = useRef()
  const logoAnchorRef = useRef()
  const breakpoints = useMediaQueryContext()
  const [headerState, updateHeaderState] = useHeaderStore()
  const { menuOpen } = headerState
  const { location, navigate } = router

  useEffect(() => {
    if ( togglerAnimation.current ) {
      togglerAnimation.current.start(menuOpen ? `menuOpened` : `menuClosed`)
    }
    
  }, [menuOpen, togglerAnimation]);

  useEffect(() => {
    async function togglerEnter() {
      await delay(300)
      await togglerAnimation.current.start(`visible`)
    }

    togglerEnter()
  }, [])

  const {
    ref: togglerRef,
    Portal: MenuPortal,
    openPortal: openOverlay,
    closePortal: closeOverlay,
  } = usePortal({
    isOpen: menuOpen,
    closeOnOutsideClick: false,
    closeOnEsc: true,
  })

  const toggleMenu = useCallback(state => {
    const newState = typeof state !== `undefined` ? state : !menuOpen

    if (! breakpoints.lg) {
      if (newState === true) {
        closeOverlay()
      } else {
        openOverlay()
      }
    }

    updateHeaderState({
      menuOpen: newState,
    })
  }, [menuOpen, updateHeaderState, breakpoints.lg, closeOverlay, openOverlay])

  const hashChangeHandler = useCallback(e => {
    if ( menuOpen ) {
      toggleMenu()
    }
    
  }, [menuOpen, toggleMenu])
  
  useEffect(() => {
    window.addEventListener(`hashchange`, hashChangeHandler)
    
    return () => window.removeEventListener(`hashchange`, hashChangeHandler)
  }, [hashChangeHandler])

  useEffect(() => {
    if ( menuOpen ) {
      toggleMenu()
    }

    // prevent logo link from staying in focus state on route change
    if (logoAnchorRef.current) {
      logoAnchorRef.current.blur()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])


  const handleLogoClick = useCallback(e => {
    e.preventDefault()
    
    const { currentTarget } = e
    
    // if home just scroll to top
    if (location.pathname === currentTarget.pathname) {
      window.history.replaceState(null, null, ' ')
      window.dispatchEvent(new HashChangeEvent('hashchange'))
    } else {
      navigate(currentTarget.href)
    }

  }, [location.pathname, navigate])

  const bgClassName = useMemo(() => menuOpen ? `bg-black` : ``, [menuOpen])

  return (
    <header className={` ${className} ${bgClassName}`} {...props}>
      <div className="header-logo">
        <a ref={logoAnchorRef} href="/" onClick={handleLogoClick}><Logo /></a>
      </div>
  
        <AnimatePresence initial={false}>
          {breakpoints.lg && menuOpen ? (
            <MainNav variants={desktopMainNavVariants} initial="closed" exit="closed" animate="opened" />
          ) : null}
        </AnimatePresence>

      <NavToggler
        className="absolute right-0 mr-6"
        onClick={toggleMenu}
        isOpen={menuOpen}
        ref={togglerRef}
        buttonAnimation={togglerAnimation}
      />

      {! breakpoints.lg && (
      <MenuPortal>
        <motion.div
          className="menu-overlay hidden py-10 fixed z-100 inset-0 bg-black text-white flex justify-center items-center"
          // style={{ zIndex: 100, }}
          variants={menuVariants}
          initial="closed"
          onClick={toggleMenu}
          animate={menuOpen ? `opened` : `closed`}>
          <MainNav />
        </motion.div>
      </MenuPortal>
      )}
      <MainNavStyles />
    </header>
  )
})

HeaderBase.propTypes = {
  siteTitle: PropTypes.string,
}

HeaderBase.defaultProps = {
  siteTitle: ``,
}

const Header = styled(HeaderBase)`
  transition: all .3s ease-in-out;
  
  ${MainNav} {
    margin-left: auto;

    ul {
      ${tw`flex flex-row flex-nowrap`}
    }

    li {
      + li {
        ${tw`ml-8`}
      }
    }

    a {
      font-weight: 600;
    }
  }
`


export default Header
