import React from 'react'
import { Match, Location } from '@reach/router'

export const motionTransition = {
  type: `spring`,
  stiffness: 20,
  damping: 20,
  mass: 0.25,
  restSpeed: .5,
  restDelta: .5,
}

export const scrollTransition = {
  ...motionTransition,
  mass: 2,
  damping: 30,
  stiffness: 60,
}

export async function delay(ms) {
  await new Promise(resolve => setTimeout(resolve, ms))
}

export const intersectThresholds = Array.from({length: 50}, (v, k) => (k*2+1) / 100)

export const withRouter = Component => props => (
  <Location>
    {router => <Component {...props} router={router} />}
  </Location>
)

export const emptyVariants = { visible: {}, hidden: {} }

export function isSSR() {
  return typeof window === `undefined`
}
