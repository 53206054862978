import React, {createContext, useContext, useMemo} from 'react'
import { useMediaLayout } from 'use-media'
import { theme } from 'twconfig'

export function useBreakpoint(breakpoint) {
  const minWidth = theme.screens[breakpoint]

  const initialState = useMemo(() => {
    return typeof window !== `undefined` ? window.innerWidth >= parseInt(minWidth) : false
  }, [minWidth])

  return useMediaLayout(`(min-width: ${minWidth})`, initialState)
}

export const MediaQueryContext = createContext()

export default function MediaQueryProvider({children}) {
  const value = {
    sm: useBreakpoint(`sm`),
    md: useBreakpoint(`md`),
    lg: useBreakpoint(`lg`),
    xl: useBreakpoint(`xl`),
  }

  return (
    <MediaQueryContext.Provider value={value}>
      {children}
    </MediaQueryContext.Provider>
  );
}

export function useMediaQueryContext() {
  return useContext(MediaQueryContext);
}
