import React from 'react'
import { ParallaxProvider } from 'react-scroll-parallax'
import MediaQueryProvider from '../context/media'
import { HeaderStateProvider } from '../context/header'

const RootWrapper = ({ element }) => {
  return (
    <HeaderStateProvider>
      <MediaQueryProvider>
        <ParallaxProvider>
          {element}
        </ParallaxProvider>
      </MediaQueryProvider>
    </HeaderStateProvider>
  )
}

export default RootWrapper
